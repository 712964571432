"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenTransferContainer = void 0;
const use_token_transfer_1 = require("@/shared/token-transfer-container/hooks/use-token-transfer");
const React = __importStar(require("react"));
const select_token_transfer_1 = require("@/shared/token-transfer-container/selectors/select-token-transfer");
const asset_url_1 = require("onefx/lib/asset-url");
const use_token_details_1 = require("@/shared/token-transfer-container/hooks/use-token-details");
const select_token_details_1 = require("@/shared/token-transfer-container/selectors/select-token-details");
const iso_i18n_1 = require("onefx/lib/iso-i18n");
const react_1 = require("react");
const TokenTransferContainer = ({ transactionHash, }) => {
    var _a;
    const { transferData, transferLoading, transferError, transferRefetch } = (0, use_token_transfer_1.useTokenTransfer)(transactionHash);
    const transfers = (0, select_token_transfer_1.selectTokenTransfer)(transferData);
    const { tokenDetailsData, tokenDetailsLoading, tokenDetailsError, tokenDetailsRefetch, } = (0, use_token_details_1.useTokenDetails)((_a = transfers === null || transfers === void 0 ? void 0 : transfers.at(0)) === null || _a === void 0 ? void 0 : _a.tokenContractAddress);
    const tokenDetails = (0, select_token_details_1.selectTokenDetails)(tokenDetailsData);
    const refetch = (0, react_1.useCallback)(() => {
        (async () => {
            await transferRefetch();
            await tokenDetailsRefetch();
        })();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("h2", { className: "card-title list-title-description" }, "Token Transfers"),
        React.createElement("div", { className: "list-top-pagination-container-wrapper" },
            React.createElement("div", { className: "pagination-container position-top ", "data-pagination-container": "" },
                React.createElement("ul", { className: "pagination" }))),
        (transferError || tokenDetailsError) && (React.createElement("button", { onClick: refetch, className: "alert alert-danger col-12 text-left" },
            React.createElement("span", { className: "alert-link" }, (0, iso_i18n_1.t)("info.err")))),
        React.createElement("div", { "data-empty-response-message": "", style: { display: "none" } },
            React.createElement("div", { className: "tile tile-muted text-center", "data-selector": "empty-logs-list" }, "There are no token transfers for this transaction")),
        React.createElement("div", { "data-items": "" },
            !(transferLoading || tokenDetailsLoading) && !(transfers === null || transfers === void 0 ? void 0 : transfers.length) && (React.createElement("div", { "data-empty-response-message": true },
                React.createElement("div", { className: "tile tile-muted text-center", "data-selector": "empty-transactions-list" }, "No token transfers in this transaction."))),
            !(transferLoading || tokenDetailsLoading) &&
                (transfers === null || transfers === void 0 ? void 0 : transfers.map((tf) => (React.createElement("div", { className: "tile tile-type-token-transfer fade-in", key: tf.transactionHash },
                    React.createElement("div", { className: "row justify-content-end" },
                        React.createElement("div", { className: "col-12 col-md-4 col-lg-2 d-flex align-items-center justify-content-start justify-content-lg-center tile-label" }, "Token Transfer"),
                        React.createElement("div", { className: "col-12 col-md-8 col-lg-10 d-flex flex-column text-nowrap" },
                            React.createElement("a", { className: "text-truncate", "data-test": "transaction_hash_link", href: (0, asset_url_1.assetURL)(`tx/${tf.transactionHash}`) }, tf.transactionHash),
                            React.createElement("span", { className: "text-nowrap" },
                                React.createElement("a", { href: (0, asset_url_1.assetURL)(`address/${tf.transactionHash}`) },
                                    React.createElement("span", null,
                                        React.createElement("span", { className: "d-none d-md-none d-xl-inline" }, tf.fromAddress))),
                                " ",
                                "\u2192",
                                " ",
                                React.createElement("a", { href: (0, asset_url_1.assetURL)(`address/${tf.toAddress}`) },
                                    React.createElement("span", null, tf.toAddress))),
                            React.createElement("span", { className: "tile-title" },
                                tf.amountWithDecimals,
                                React.createElement("a", { "data-test": "token_link", href: (0, asset_url_1.assetURL)(`address/${tf.tokenContractAddress}`) },
                                    " ", tokenDetails === null || tokenDetails === void 0 ? void 0 :
                                    tokenDetails.symbol))))))))),
        React.createElement("div", { className: "pagination-container  position-bottom", "data-pagination-container": "" },
            React.createElement("ul", { className: "pagination" }))));
};
exports.TokenTransferContainer = TokenTransferContainer;
